<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>
        Filters
      </h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Events</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="typeFilter"
            :options="typeOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:typeFilter', val)"
          />
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Record</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="relationModelFilter"
            :options="relationModelOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:relationModelFilter', val)"
          />
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Aangepast door</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="userFilter"
            :options="userOptions"
            class="w-100"
            label="fullname"
            :reduce="val => val.id"
            @input="(val) => $emit('update:userFilter', val)"
          />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    vSelect,
  },
  props: {
    typeOptions: {
      type: Array,
      default: null,
    },
    userOptions: {
      type: Array,
      default: null,
    },
    relationModelOptions: {
      type: Array,
      default: null,
    },
    userFilter: {
      type: Number,
      default: null,
    },
    relationModelFilter: {
      type: String,
      default: null,
    },
    typeFilter: {
      type: [Number, null],
      default: null,
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
