import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import errorAndSuccesDefaultMessage from '@core/app-config/errorAndSuccesDefaultMessage'

export default function useLogList() {
  // Use toast
  const toast = useToast()

  const refLogListTable = ref(null)

  // Table Handlers
  const tableLogColumns = [
    { label: 'Event', key: 'action', sortable: true },
    { label: 'Aangepast door', key: 'user', sortable: true },
    { label: 'Record', key: 'object', sortable: true },
    { label: 'Record ID', key: 'objectID', sortable: true },
    { label: 'IP', key: 'ip', sortable: false },
    { label: 'Datum aangemaakt', key: 'timestamp', sortable: true },
    { label: 'Acties', key: 'actions' },
  ]

  const perPage = ref(25)
  const totalActivities = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [25, 50, 75, 100]
  const userFilter = ref(null)
  const relationModelFilter = ref('')
  const relationID = ref('')
  const typeFilter = ref(null)
  const showOverlay = ref(true)
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refLogListTable.value ? refLogListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalActivities.value,
    }
  })

  const refetchData = () => {
    refLogListTable.value.refresh()
  }

  watch([currentPage, perPage, userFilter, relationModelFilter, relationID, typeFilter], () => {
    refetchData()
  })

  const userOptions = []

  const fetchLogs = (ctx, callback) => {
    store
      .dispatch('apps-log/fetchActivities', {
        perPage: perPage.value,
        page: currentPage.value,
        userID: userFilter.value,
        relationModel: relationModelFilter.value,
        type: typeFilter.value,
        sortDesc: isSortDirDesc.value,
      })
      .then(response => {
        const { items, _meta } = response.data

        callback(items)
        showOverlay.value = false
        totalActivities.value = _meta.totalCount
      })
      .catch(() => {
        showOverlay.value = false
        toast({
          component: ToastificationContent,
          props: {
            title: 'Ophalen Aciviteiten',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: `${errorAndSuccesDefaultMessage.fetchDataError}`,
          },
        })
      })
  }

  const relationModelOptions = [
    { label: 'Gebruiker', value: 'User' },
    { label: 'Relatie', value: 'Relation' },
    { label: 'Order', value: 'Order' },
  ]

  const resolveRelationModel = model => {
    if (model === 'User') return 'Gebruiker'
    if (model === 'Relation') return 'Relatie'
    if (model === 'Order') return 'Order'
    return ''
  }

  const resolveTypeName = status => {
    if (status === 1) return 'View'
    if (status === 2) return 'Update'
    if (status === 3) return 'Delete'
    if (status === 4) return 'Create'
    if (status === 5) return 'Login'
    if (status === 6) return 'Password reset'
    if (status === 7) return 'Refresh token'
    if (status === 8) return 'Login mislukt'
    return ''
  }

  const typeOptions = [
    { label: 'View', value: 1 },
    { label: 'Update', value: 2 },
    { label: 'Delete', value: 3 },
    { label: 'Create', value: 4 },
    { label: 'Login', value: 5 },
    { label: 'Password Reset', value: 6 },
    { label: 'Refresh Token', value: 7 },
  ]

  return {
    fetchLogs,
    tableLogColumns,
    perPage,
    currentPage,
    relationModelFilter,
    typeFilter,
    userFilter,
    totalActivities,
    dataMeta,
    perPageOptions,
    refLogListTable,

    refetchData,

    relationModelOptions,
    typeOptions,
    resolveTypeName,
    userOptions,
    showOverlay,
    resolveRelationModel,
  }
}
